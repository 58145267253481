/**
 * DEBUG TRAY
 * @description interface for team to quickly test new or modified application features
 *
 * -- Why do we need this?
 * Testing a specific situation can take a fair amount of setup time.
 * For example: if you need to test the review page, you need to go navigate the entire app to test a change.
 *
 * Additionally the functionality can be used by Cypress to feature tests specifics situations over more (expensive) e2e tests
 */
import { AfterViewInit, ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, inject, Input, ViewChild } from '@angular/core';
import type KinDrawer from '@kin/web-components/dist/external/kin-drawer/kin-drawer';
import '@kin/web-components/dist/external/kin-drawer/kin-drawer';
import '@kin/web-components/dist/external/kin-tabs/kin-tabs';
import '@kin/web-components/dist/external/kin-tabs/kin-tab';
import '@kin/web-components/dist/external/kin-tabs/kin-tab-panel';

import { SESSION_STORAGE } from '@ng-web-apis/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideBug } from '@ng-icons/lucide';
import { AppConfigModule, AppContext } from '../../app-config';
import { DebugNavToFormComponent } from './debug-nav-to-form/debug-nav-to-form.component';
import { DebugQuoteDraftStateComponent } from './debug-quote-draft-state/debug-quote-draft-state.component';
import { DebugUserStateComponent } from './debug-user-state/debug-user-state.component';
import { DebugResumeComponent } from './debug-resume/debug-resume.component';
import '@kin/web-components/dist/external/kin-dialog/kin-dialog';
import { DebugPolicyStateComponent } from './debug-policy-state/debug-policy-state.component';

@Component({
  selector: 'app-debug-tray',
  standalone: true,
  imports: [AppConfigModule, NgIconComponent, DebugNavToFormComponent, DebugQuoteDraftStateComponent, DebugUserStateComponent, DebugResumeComponent, DebugPolicyStateComponent],
  templateUrl: './debug-tray.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideIcons({ lucideBug })],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugTrayComponent implements AfterViewInit {
  @ViewChild('debugTray') debugTray!: ElementRef<KinDrawer>;
  public sessionStorage = inject(SESSION_STORAGE);

  private viewReady = false;
  @Input() set show(shouldShow: boolean) {
    if (this.viewReady) {
      if (shouldShow) {
        this.debugTray.nativeElement.show();
      } else {
        this.debugTray.nativeElement.hide();
      }
    }
  }

  @AppContext() private _appContext!: AppContext;
  public notProd = this._appContext.notProd;

  public ngAfterViewInit(): void {
    this.viewReady = true;
  }
}
